@font-face {
    font-family: 'DaimlerCSBold';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Bold.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSBoldItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-BoldItalic.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSDemi';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Demi.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-Demi.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSDemiItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-DemiItalic.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-DemiItalic.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSExtraBold';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBold.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSExtraBoldItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBoldItalic.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-ExtraBoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Italic.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-Light.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSLight';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Light.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-Light.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSLightItalic';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-LightItalic.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-LightItalic.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCSRegular';
    src: url('assets/fonts/DaimlerCS/DaimlerCSPro-Regular.otf') format('opentype'),
    url('assets/fonts/DaimlerCS/DaimlerCSPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'DaimlerCARegular';
    src: url('assets/fonts/DaimlerCA/DaimlerCAPro-Regular.otf') format('opentype'),
    url('assets/fonts/DaimlerCA/DaimlerCAPro-Regular.woff') format('woff');
}

@import "../node_modules/bootstrap/scss/bootstrap";

@import 'ngx-toastr/toastr';

@import '../node_modules/@swimlane/ngx-datatable/index';
@import '../node_modules/@swimlane/ngx-datatable/themes/material';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons';

@import 'styles/core';
@import 'styles/base';
@import 'styles/buttons';
@import 'styles/sidebar';
@import 'styles/alert';
@import 'styles/variables';
@import 'styles/datatable';
@import 'styles/nav-tabs';
@import 'styles/modals';
@import 'styles/forms';
