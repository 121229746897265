@import "variables";

.vus-modal {
    font-family: 'DaimlerCSRegular';
    background: $dark-grey;
    color: $white-soft;
    padding: $spacing_32 $spacing_40;
    border-radius: 5px !important;

    .modal-header {
        flex-flow: column;
        padding: 0;
        border: none;
        margin-bottom: $spacing_32;
        align-items: flex-start;

        &.close-top-right {
            flex-flow: row;
        }

        .modal-title {
            font-size: 24px;
            line-height: 24px;
            margin: 0;
        }

        .modal-subtitle {
            margin-top: $spacing_8;
            font-size: 14px;
            line-height: $spacing_16;
            margin-bottom: 0;
        }
    }

    .modal-body {
        padding: 0;

        .file-select-container {
            display: flex;
            flex-direction: column;
        }
    }

    .modal-footer {
        margin-top: $spacing_48;
        padding: 0;
        border: none;
    }

    .modal-footer > * {
        margin: 0;
    }
}

.modal-content {
    background: rgb(68, 68, 68) !important;
    color: $white-soft !important;
    font-family: 'DaimlerCSRegular' !important;
}

.modalBig .modal-dialog {
    min-width: 800px;

    .modal-content {
        min-height: 575px !important;
    }
}

.modalVeryBig .modal-dialog {
    min-width: 1300px;
}

.modal-form {
    background-color: $grey-soft;
    height: 100%;
    padding: 0.5em 1em 1em 1em;

    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.hideModal {
    visibility: hidden;

    .visible {
        visibility: hidden !important;
    }
}
