@import './variables';

// Page Structure

.content {
    position: absolute;
    top: $header_height;
    right: 0;
    bottom: 0;
    left: $sidebar_width;
    padding: $spacing_24 $spacing_28;
    overflow: auto;

    .vus-page-title {
        color: $white-soft;
        font-family: 'DaimlerCSBold';
        font-size: 24px;
        line-height: 28px;
        margin-bottom: $spacing_16;
    }

    .vus-page-description {
        color: $white-soft;
        font-family: 'DaimlerCSRegular';
        font-size: 14px;
        margin-bottom: $spacing_32;
    }
}

