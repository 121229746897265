@import 'variables';

.datatable-header {
    background: $grey-soft !important;
}

.datatable-header-cell {
    font-family: 'DaimlerCSDemi' !important;
    color: $white-soft !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    background-color: transparent !important;
    padding: 0.9rem 20px !important;

    .datatable-header-cell-wrapper {
        color: $grey-headline;
    }
}

.datatable-body {
    background: $grey-opacity !important;
}

.datatable-footer {
    background: $grey-soft !important;
}

.footer-form {
    padding-left: 20px;
}

.add-button {
    margin-top: auto;
}

.ngx-datatable .datatable-footer .datatable-footer-inner {
    color: white;
}

.datatable-body-cell {
    font-size: 14px !important;
    color: white !important;
    padding: 0.9rem 20px 0.7rem 20px !important;
    display: flex !important;
    align-items: center;

    .editable {
        display: table;

        input[type='text'],
        textarea {
            font-size: 18px !important;
            margin: 0px !important;
            border: 1px solid transparent;
            border-radius: 2px;
            padding: 0 4px;
            resize: none;
        }

        textarea {
            padding-top: 3px;
        }

        .datatable-body-cell-input {
            color: white !important;
            background-color: transparent !important;
        }

        .datatable-body-cell-input-active {
            color: white !important;
            border: 1px solid $bright-blue !important;
            background-color: transparent !important;
            display: table-cell;
            width: 100%;
            z-index: 1000 !important;
            background-color: $grey-opacity !important;
        }
    }
}

.datatable-row-detail {
    background: rgba(76, 76, 76, 0.52) !important;
    height: auto !important;
    padding: 0 !important;
}

.ngx-datatable.material .datatable-header {
    background: $light-grey;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
.datatable-body-row:hover
.datatable-row-group {
    background-color: $grey-soft2 !important;
}

.ngx-datatable .datatable-header {
    border-bottom: none;
}

.datatable-row-wrapper {
    position: relative;
    z-index: 1;
}

.datatable-row-wrapper:hover {
    background-color: $grey-soft2 !important;
}

.datatable-row-center {
    padding-right: 42px;
    padding-left: 28px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

.ngx-datatable.material
.datatable-body
.datatable-row-wrapper:last-child
.datatable-body-cell {
    border-bottom: none;
}

.ngx-datatable
.datatable-header
.datatable-header-cell.sort-active
.datatable-header-cell-wrapper {
    color: $bright-blue;
}

.ngx-datatable .datatable-header .datatable-header-cell.sort-active .sort-btn {
    color: $bright-blue;
}

.ngx-datatable .datatable-icon-sort-unset::before {
    display: none;
}

.datatable-scrollable-small {
    max-height: 420px;
    min-height: 420px;
}

.datatable-scrollable {
    max-height: 500px;
    min-height: 500px;
}

.modal-body {
    .datatable-scroll {
        width: 100% !important; // Workaround: NGX-Datable is buggy in bootstrap-modal
    }
}

.datatable-scrollable .datatable-row-center,
.datatable-scrollable-small .datatable-row-center {
    padding-right: 0px;
    padding-left: 0px;
}

.datatable-body-cell-label {
    white-space: normal !important;
    display: flex;
    overflow-x: visible;
    height: 32px;
    align-items: center;
    font-size: 16px;

    span {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.table-header {
    background-color: $grey-soft;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid $grey-hover;

    span {
        font-family: 'DaimlerCSRegular' !important;
        font-weight: 900 !important;
        color: $grey-headline;
        font-size: 18px;
    }
}

.datatable-footer-inner {
    overflow: hidden;

    custom-table-footer {
        display: flex;
        padding-left: 15px;
    }
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
    color: white;
}

.ngx-datatable.material {
    box-shadow: none;
}

.logging-column {

    .datatable-body-cell-label {
        width: 50%;
        height: 38px;
    }
}

/* Hide the browser's default checkbox, default radio button */
.checkbox-container input,
.checkradio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid $grey-soft5;
    border-radius: 4px;
}

/* Create a custom radiobutton */
.radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $background-black;
    border-radius: 50%;
    margin: 0px;
}

/* On mouse-over, add a transparent background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: transparent;
}

/* When the checkbox is checked, add a transparent background */
.checkbox-container input:checked ~ .checkmark {
    background-color: transparent;
}

/* On mouse-over, add a  background color */
.checkradio-container:hover input ~ .radiomark {
    background-color: $background-black;
}

/* When the radiomark is checked, add a  background */
.checkradio-container input:checked ~ .radiomark {
    background-color: $background-black;
}

/* Create the checkmark - radiomark/indicator (hidden when not checked) */
.checkmark:after,
.radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark - radiomark when checked */
.checkbox-container input:checked ~ .checkmark:after,
.checkradio-container input:checked ~ .radiomark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid $bright-blue;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Style the radiomark/indicator */
.checkradio-container .radiomark:after {
    left: 6px;
    top: 6px;
    width: 5px;
    height: 2px;
    border: solid $bright-blue;
    border-width: 3px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 50%;
}

