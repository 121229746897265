@import './variables';

// General
button.btn {
    font-family: 'DaimlerCSDemi';
    color: $white-soft;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    padding: 0 22px;
    text-align: center;
    vertical-align: middle;
    box-shadow: none !important;

    &:not(:last-child) {
        margin-right: 24px;
    }
}

button.btn-primary,
button.btn-outline-primary,
button.btn-secondary,
button.btn-cancel,
label.btn-primary {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    fa-icon {
        display: flex;
    }

    span {
        height: 16px;
        line-height: 20px;
        font-size: 16px;
    }
}

.button-icon {
    color: #09d2e4;
}

.main-icon {
    &:hover {
        background-color: $bright-blue-hover !important;
    }
}

// Primary
button.btn.btn-primary {
    border: none;
    background-color: $bright-blue;

    &:hover {
        background-color: $bright-blue-hover;
    }

    &:active {
        background-color: $bright-blue-hover !important;
    }

    &:disabled {
        background-color: $bright-blue-disabled;
        color: $white-soft-disabled;
    }
}

// Secondary
button.btn.btn-secondary {
    border: none;
    background-color: $grey-secondary;

    &:hover {
        background-color: $grey-secondary-hover;
    }

    &:active {
        background-color: $grey-secondary-hover !important;
        box-shadow: none;
    }

    &:disabled {
        background-color: $grey-secondary-disabled;
        color: $white-soft-disabled;
    }
}

// Outline
button.btn.btn-outline-primary {
    color: $bright-blue;
    border-color: $bright-blue;

    &:active {
        color: $bright-blue;
        border-color: $bright-blue;
        background-color: transparent;
    }

    &:hover {
        color: $bright-blue-hover;
        background: none;
        border-color: $bright-blue-hover;

        .button-icon {
            color: $bright-blue-hover;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
    }

    &:disabled {
        color: $bright-blue-disabled;
        border-color: $bright-blue-disabled;
    }
}

button.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(9, 210, 228, 0.5);
}

// Cancel
.btn-cancel,
.btn-cancel:hover {
    background-color: $grey-svg-default;
    border: none;
}

// Close
.modal-header {
    button.close {
        padding: 0;
        margin: 0;
        color: $bright-blue;
        opacity: initial;

        &:hover {
            color: $bright-blue-hover
        }

        &:focus {
            outline: 0;
            opacity: initial !important;
        }

        &:active {
            outline: 0;
            color: $bright-blue-hover;
            border-color: $bright-blue-hover;
        }

        fa-icon {
            font-size: 24px;
        }
    }
}

// Option
.option-button {
    background: transparent;
    border: none;
    float: left;
    height: 20px;
    margin: 2px;
    width: 25px !important;
    padding: 0;

    path {
        transition: 0.25s;
        fill: $bright-blue;
    }

    .radio {
        color: $soft-white;

        &:hover {
            color: $bright-blue;
        }
    }

    .invisible {
        cursor: default;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        path {
            transition: 0.25s;
            fill: $bright-blue-hover;
        }
    }

    &:disabled {
        path {
            transition: 0.25s;
            fill: $bright-blue-disabled;
        }
    }
}

// Round
.round-button-container {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    icon-button:nth-child(2) {
        margin-left: 16px;
    }

    icon-button:nth-child(3) {
        margin-left: 16px;
    }
}

.round-button {
    border: none;
    border-radius: 50%;
    height: 48px !important;
    padding: 0 !important;
    width: 48px;
    color: $white-soft;
    text-align: center;
    background-color: $bright-blue;
    margin: 0;

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }

    &:disabled {
        color: $white-soft-disabled !important;
        background-color: $bright-blue-disabled !important;

        .svg-inline--fa {
            color: $white-soft-disabled;
        }
    }
}

// DataPool
.btnDatapool {
    margin-bottom: 2px;
    background: transparent;
    border: none;
    padding: 0 25px 0 5px !important;
    color: white;
    font-size: 16px;
    line-height: 16px;
    background: url('../assets/icons/dropdown-arrow.svg') 90% / 8% no-repeat;
    background-size: 8px;

    &:hover {
        color: white;
    }

    &:focus {
        box-shadow: none;
    }
}
