@import 'variables';

body {
    font-family: 'DaimlerCSRegular';
    overflow-x: hidden;
    color: $soft-white;
    background: $background-black !important;
}

a:hover {
    color: white !important;
    opacity: 1 !important;
}

label {
    margin-bottom: 0.5rem;
}

.card-title,
.container-title {
    color: $white-soft;
    font-family: 'DaimlerCSRegular';
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px !important;
    display: inline-block;
}

.dropdown-menu {
    background: $dark-grey !important;
}

.dropdown-item {
    color: white !important;
}

.dropdown-toggle::after {
    border: none !important;
    content: none;
}

.dropdown-item:hover {
    background: $grey-hover !important;
    color: white;
}

option#calculationInstanceSelector {
    color: $white-soft;
    font-family: 'DaimlerCSRegular', sans-serif;
}

.calculate-container {
    padding: 32px 48px 24px 48px;
    background: $card-grey !important;
}

.vus-card {
    background-color: $grey-opacity !important;
}

.vus-card-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    background-color: #333 !important;
}

.card-body  {
    color: rgb(251, 251, 251) !important;
}

pre {
    color: $white;
}

.vus-container {
    border-radius: 4px;
    background-color: $dark-grey !important;
}

.vus-title {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin: $spacing_12 $spacing_48 $spacing_28 $spacing_48;

    span {
        line-height: 48px;
        font-size: 24px;
        color: #fbfbfb;
    }
}

.uploadtoolInfoContainer {
    margin: 26px 40px;

    .vus-title {
        margin-right: 8px;
        margin-left: 8px;
    }
}

.card ~ .card {
    margin-top: 1em;
}

// Tooltip
.tooltip.show {
    opacity: 1;
}

.note-tooltip .tooltip-inner {
    width: 500px;
    max-width: 500px;
    padding: 1rem;
    background-color: $grey-soft !important;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $grey-soft;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: $grey-soft;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: $grey-soft;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: $grey-soft;
}

.long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.text-center {
    div {
        position: relative;
        left: 50%;
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

.text-right {
    text-align: right;
}

// Icons
.icon-aqua {
    .svg-inline--fa {
        color: $bright-blue;
    }
}

.icon-red {
    .svg-inline--fa {
        color: $status-red;
    }
}

.icon-yellow {
    .svg-inline--fa {
        color: $status-yellow;
    }
}

.icon-green {
    .svg-inline--fa {
        color: $status-green;
    }
}

.icon-grey {
    .svg-inline--fa {
        color: $status-grey;
    }
}

.icon-white {
    .svg-inline--fa {
        color: $white;
    }
}

label.checkbox-container {
    opacity: 1 !important;
    span.lb-alignment {
      margin-left: 32px;
      font-family: "DaimlerCSRegular";
      font-size: 14px;
      vertical-align: middle;
      display: inline-block;
      line-height: 1em;
    }
  }

  .empty-row {
    color: $soft-white !important;
  }
