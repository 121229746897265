@import './variables';

input.form-control,
select.form-control,
option.form-control {
    background: rgba(77, 77, 77, 0.2);
    border: 1px solid #999;
    color: $white-soft;
    font-size: 16px;
    border-radius: 4px;
    line-height: 20px !important;
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: url('../assets/icons/dropdown-arrow.svg') no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-origin: content-box;
}

select::-ms-expand {
    display: none;
}

select.form-control,
input.form-control {
    padding: 8px 16px;
    line-height: 18px;
}

.form-control:disabled {
    background-color: $grey-disabled !important;
    opacity: 0.4 !important;
}

select > option {
    color: $white;
}

.form-control:focus {
    background-color: $grey-selected !important;
    border: 1px solid $white !important;
    color: $white-soft !important;
    opacity: 1 !important;
    box-shadow: none !important;
}

optgroup[label] {
    background: rgba(77, 77, 77, 0.2);
    font-family: 'DaimlerCSRegular', sans-serif;
}

// Validierung
.invalid-feedback,
.invalid-feedback:focus {
    color: $danger;
}

.form-control.is-invalid,
.form-control.is-invalid:focus {
    border-color: $danger;
    background-image: none !important;
}

// Forms
.row-spacer {
    border-right: 25px solid transparent;
}

.col-spacer {
    border-right: 25px solid transparent;
    border-bottom: 25px solid transparent;

    input {
        margin-left: 25px;
    }
}

.form-label {
    white-space: nowrap;
    margin-left: 40px;
}

.form-group {
    margin-bottom: 1rem;
}

// Textarea
textarea.form-control,
textarea.form-control:read-only {
    color: $white-soft;
    background: rgba(77, 77, 77, 0.2);
    border: 1px solid #999;
}

textarea.form-control:read-only {
    cursor: default;
    opacity: 0.5;
}

textarea.form-control:focus {
    border: 1px solid $white-soft;
}

textarea.form-control.is-invalid,
textarea.form-control.is-invalid:focus {
    border-color: $danger;
    background-image: none;
}

// Checkboxes
.checkbox-row {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}

.checkbox {
    margin-right: 4px;
}

.checkbox-label {
    margin-right: 20px;
}
