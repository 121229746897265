// Daimler Corporate Design
// https://designnavigator.daimler.com/Daimler_Color_System

// sizes
$spacing_4: 4px;
$spacing_8: 8px;
$spacing_12: 12px;
$spacing_16: 16px;
$spacing_24: 24px;
$spacing_28: 28px;
$spacing_32: 32px;
$spacing_40: 40px;
$spacing_48: 48px;

$header_height: 56px;
$sidebar_width: 220px;

// colors
$soft-black: #151515;
$soft-white: #fbfbfb;
$nav-background: #333333;
$grey-headline: #a0a0a0;
$bright-blue: #09d2e4;
$bright-blue-hover: #3994a6;
$bright-blue-disabled: #43a8B1;
$nav-primary-navy: #234C55;
$nav-secondary-navy: #353F41;

$dark-blue: #00677f;
$dark-blue-soft: #1e8c96;
$dark-grey: #444444;
$light-grey: #c8c8c8;
$background-black: #292929;
$white: #ffffff;
$grey-svg-default: #686868;
$white-soft: #fbfbfb;
$white-soft-disabled: #bcbcbc;
$blue-soft: #49666c;
$danger: #ce5151;
$grey-disabled: #242424;
$grey-selected: #5e5e5e;
$grey-unselected: #626262;
$card-grey: rgba(68, 68, 68, 0.69);
$grey-hover: #5a5a5a;
$grey-opacity: #373737;
$grey-soft: #323232;
$grey-soft2: #434343;
$grey-soft3: #707070;
$grey-soft4: #535353;
$grey-soft5: #979797;
$grey-secondary: #636363;
$grey-secondary-hover: #474747;
$grey-secondary-disabled: #bbbbbb;

// Tile Colors
$tile-pink: #ac6395;

$light-grey: #e6e6e6;
$light-grey-40k: #9e9e9e;

// Status-Colors
$status-yellow: #ffd559;
$status-red: #ff0000;
$status-green: #24a04a;
$status-grey: #999999;
