@import 'variables';

.zke-nav-tabs {
    padding: 10px 25px 0 25px;
    border-radius: 4px 4px 0 0;
    background-color: $nav-primary-navy;
    border-bottom: none;
}

ul.zke-nav-tabs li a.zke-nav-link {
    border: none;
    font-family: 'DaimlerCSRegular' !important;
    background: transparent !important;
    color: $grey-headline;
    font-family: 'Daimler CS';
    font-size: 18px !important;
    letter-spacing: -0.68px !important;
    line-height: 21px !important;
    text-align: center !important;
    padding-bottom: 10px;
}

ul.zke-nav-tabs li a.zke-nav-link.active {
    background-color: transparent;
    font-family: 'DaimlerCSBold' !important;
    color: $bright-blue !important;
    border-bottom: $spacing_4 solid $bright-blue;
    padding-bottom: $spacing_8;
}

.zke-nav-tabs .zke-nav-item {
    margin-bottom: 0;
    padding-left: $spacing_8;
    padding-right: $spacing_8;
}


ul.uploadToolTabset.zke-nav-tabs {
    background-color: $nav-secondary-navy;
}
